













import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import ExtraGuaranteeOption from '~/components/organisms/ExtraGuarantee/ExtraGuaranteeOption/ExtraGuaranteeOption.vue';
import { useExtraGuaranteeOptions } from '~/composables';

export default defineComponent({
  name: 'ExtraGuaranteeOptions',
  components: {
    ExtraGuaranteeOption,
  },
  props: {
    extraGuaranteeSku: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { guaranteeOptions, hasOnlyOneOption } = useExtraGuaranteeOptions();
    const selectedOption = ref(null);

    onMounted(() => {
      selectedOption.value = props.extraGuaranteeSku;
    });

    const handleSelect = (option) => {
      selectedOption.value = selectedOption.value === option.sku ? null : option.sku;
      emit('update:selectedOption', selectedOption.value);
    };

    return {
      guaranteeOptions,
      hasOnlyOneOption,
      selectedOption,
      handleSelect,
    };
  },
});
